import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import "./otpVerification.scss";
import { generateOTP, validateOTP } from "../../utils/API";
import {
  defaultWaitingTimeForResendOTP,
  reCAPTCHA_site_key,
} from "../../constants/variables";
import { ReactComponent as Edit } from "../../assets/icons/edit-3.svg";
import jsCookie from "js-cookie";
import { useCookieAuthListener } from "../../hooks";
import { Notification } from "../../helpers/notification";
import CIcon from "@coreui/icons-react";
import { freeSet } from "@coreui/icons";
import { PhoneInput } from "./styles/OTP-Verification.style";
import { RestaurantsInfo } from "../../contexts/restaurantInfo";
import { useContext } from "react";

const ResendOTP = ({ getOTP, restaurantColor }) => {
  const [timer, setTimer] = useState(defaultWaitingTimeForResendOTP);
  const [enableResendOTP, setResendOTP] = useState(false);
  const [enableResendButton, setResendButton] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      timer > 0 ? setTimer(timer - 1) : setResendOTP(true);
    }, 1000);
    if (timer == 0) {
      setResendButton(false);
    }
  }, [timer]);

  return (
    <div>
      {enableResendButton ? (
        <div>
          <Form.Text className="text-muted  otp-text">
            <span>
              Wait for&nbsp;<b> 00:{timer}</b> &nbsp;secs to re-send OTP
            </span>
          </Form.Text>
        </div>
      ) : (
        <div className="resend-button">
          <button
            // style={
            //   restaurantColor
            //     ? { backgroundColor: `${restaurantColor}` }
            //     : { backgroundColor: `#2eb85c` }
            // }
            onClick={() => getOTP({ setTimer, setResendButton })}
            disabled={enableResendButton}
          >
            Resend OTP
          </button>
        </div>
      )}
    </div>
  );
};

export default function OTPVerification({
  notificationListener,
  successCallback,
  showModal,
  setShowModal,
  restaurantColor,
}) {
  const [isValidPhoneNumber, setISValidPhoneNumber] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isInvalidOTP, setIsInvalidOTP] = useState(false);
  const [OTP, setOTP] = useState("");
  const { setCookie } = useCookieAuthListener();
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showVerify, setShowVerify] = useState(false);
  const [enableVerifyButton, setEnableVerifyButton] = useState(true);
  const [showWaitText, setShowWaitText] = useState(false);
  const { restaurant } = useContext(RestaurantsInfo);

  function updatePhoneNumber(isValid, phoneNumber, metaData) {
    setPhoneNumber(metaData.dialCode + phoneNumber);
    setISValidPhoneNumber(isValid);
  }

  function getOTP({ setTimer, setResendButton }) {
    if (phoneNumber.length <= 0) {
      Notification({
        type: "error",
        displayMessage: "Invalid phone number, Please try again.",
      });
      return;
    }

    if (!isValidPhoneNumber) {
      Notification({
        type: "error",
        displayMessage: "Invalid phone number, Please try again.",
      });
      return;
    }
    setLoading(true);
    if (setTimer) {
      setTimer(defaultWaitingTimeForResendOTP);
      setResendButton(true);
    }
    window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute(reCAPTCHA_site_key, { action: "submit" })
        .then((recaptchaToken) => generateOTP({ phoneNumber, recaptchaToken }))
        .then(() => {
          setLoading(false);
          setShowVerify(true);
          setOTP("");
          if (setTimer) {
            setTimer(defaultWaitingTimeForResendOTP);
            setResendButton(true);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    });
  }

  function handleSetOTP(event) {
    const value = event.target.value.replace(/\D/g, "");
    setOTP(value);
    if (value.length === 6) {
      setEnableVerifyButton(false);
    }
  }

  function validateUserOTP() {
    if (OTP.length < 6) {
      Notification({
        type: "error",
        displayMessage: "Invalid OTP, Please try again.",
      });
      return;
    }
    setLoading(true);
    validateOTP({ phoneNumber, OTP })
      .then((res) => {
        const cookieValue = res.replace('.gupshup.io', document.location.hostname);
        document.cookie = cookieValue;
        // jsCookie.set(
        //   "gipuserid",
        //   "3hGoQ35Gb73RdrIfzk8+R2SIve/MkBqDz5HRgOl+MSpZV9ZYuk95k1e29EbYslvG"
        // );
        if (notificationListener) {
          notificationListener();
        }
        setCookie(jsCookie.get("gipuserid"));
        setLoading(false);
        successCallback();
      })
      .catch((e) => {
        setLoading(false);
        setIsInvalidOTP(true);
      });
  }
  const handleModal = () => {
    setShowModal(false);
    setName("");
    setPhoneNumber("");
    setShowVerify(false);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleChangeNumber = () => {
    setShowVerify(false);
    setPhoneNumber("");
  };

  const createOTPComponent = showModal && (
    <div className="checkout-container">
      <div className="checkout-div">
        <div className="cancel-button" onClick={handleModal}>
          {/* <span onClick={handleModal}>
              <CIcon content={freeSet.cilX} height="20" />
            </span> */}
          X
        </div>
        <div className="modal-checkout">
          {/* <h3
            style={
              restaurantColor
                ? { color: `${restaurantColor}`, marginBottom: "60px" }
                : { color: `#2eb85c`, marginBottom: "60px" }
            }
          >
            Login
          </h3>
          <div className="phonenumber-text-container">
            <div className="phonenumber-text">Phone number</div>
          </div> */}

          <h4
            style={
              // restaurantColor
              //   ? { color: `${restaurantColor}` }
              //   : { color: `#2eb85c` }
              {
                color: `${restaurant?.meta_data?.uiColor}`,
              }
            }
          >
            Just a step away from Order
          </h4>
          <p style={{ color: "rgba(111,111,111,0.9)" }}>
            Please Enter your Mobile Number
          </p>
          <PhoneInput restaurantColor={restaurantColor}>
            <IntlTelInput
              className="number-input :focus{
                borderColor: 'green'
              }"
              style={{
                width: "100%",
                fontSize: "14px",
                height: "45px",
                position: "relative",
                left: "50%",
                transform: "translateX(-40%)",
              }}
              inputClassName="number-input"
              onFocus={{ borderColor: "red" }}
              preferredCountries={["in"]}
              placeholder="PHONE NUMBER"
              isInvalid={isValidPhoneNumber}
              onPhoneNumberChange={(isValid, phoneNumber, metaData) =>
                updatePhoneNumber(isValid, phoneNumber, metaData)
              }
            />
          </PhoneInput>
          <div className="otp-button">
            <Button
              style={{
                // backgroundColor: restaurantColor
                //   ? `${restaurantColor}`
                //   : `#2eb85c`,
                backgroundColor: `${restaurant?.meta_data?.uiColor}`,
                width: "100%",
                borderRadius: "50px",
                border: "none",
                height: "100%",
                marginBottom: "10px",
              }}
              onClick={getOTP}
              disabled={!isValidPhoneNumber && !phoneNumber.length}
              disabled={loading}
            >
              Request OTP
            </Button>
          </div>

          <Form.Text className="text-muted  otp-text">
            <p style={{ margin: "0" }}>A 6-digit OTP will be sent to your</p>
            <span> device to verify the number.</span>
          </Form.Text>

          {/* <div className="cancel-button">
            <span onClick={handleModal}>
              <CIcon content={freeSet.cilX} height="20" />
            </span>
            X
          </div> */}
        </div>
      </div>
    </div>
  );

  const verifyOTPComponent = (
    <div className="checkout-container">
      <div className="checkout-div">
        <div className="modal-verify">
          <h3
            style={{
              marginBottom: "30px",
              // color: restaurantColor ? restaurantColor : `#2eb85c`,
              color: `${restaurant?.meta_data?.uiColor}`,
            }}
          >
            OTP
          </h3>
          <div>
            <Form.Text className="text-muted  otp-text">
              <p style={{ margin: "0" }}>We have sent the verification code</p>
              <span> to your mobile number.</span>
            </Form.Text>
          </div>
          <div className="change-number">
            <h5 style={{ marginBottom: "20px", color: "rgb(111, 111, 111)" }}>
              {phoneNumber.substr(0, 2) + "-" + phoneNumber.substr(2)}
            </h5>
            <button
              style={
                {
                  backgroundColor: `${restaurant?.meta_data?.uiColor}`,
                }
                // restaurantColor
                //   ? { backgroundColor: `${restaurantColor}` }
                //   : { backgroundColor: `#2eb85c` }
              }
              onClick={handleChangeNumber}
            >
              <Edit
                style={{
                  color: "white",
                  height: "15px",
                  width: "15px",
                }}
              ></Edit>
            </button>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "45px",
            }}
          >
            <input
              type="tel"
              className="otp-input"
              maxLength="6"
              isInvalid={isInvalidOTP}
              pattern="[0-9]*"
              value={OTP}
              onChange={(e) => handleSetOTP(e)}
            />
          </div>

          <ResendOTP
            getOTP={getOTP}
            restaurantColor={restaurantColor}
            setShowWaitText={setShowWaitText}
            showWaitText={showWaitText}
          />

          <div className="verify-button">
            <Button
              style={
                // restaurantColor
                //   ? { backgroundColor: `${restaurantColor}` }
                //   : { backgroundColor: `#2eb85c` }
                {
                  backgroundColor: `${restaurant?.meta_data?.uiColor}`,
                }
              }
              onClick={validateUserOTP}
              disabled={(!isValidPhoneNumber && !phoneNumber.length) || loading}
              disabled={enableVerifyButton}
            >
              {loading ? "Submitting" : "Submit"}
            </Button>
          </div>

          <div
            style={{ top: "-25px" }}
            className="cancel-button"
            onClick={handleModal}
          >
            {/* <span onClick={handleModal}>
              <CIcon content={freeSet.cilX} height="20" />
            </span> */}
            X
          </div>
        </div>
      </div>
    </div>
  );

  const view = showVerify ? verifyOTPComponent : createOTPComponent;

  return view;
}
