import React from "react";
import { Modal } from './styles/Modal'

export default function ({onClose, style, children}) {
    return (
        <Modal>
            <Modal.Container style={style}>
                { children }
            </Modal.Container>
        </Modal>
    )
}