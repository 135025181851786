import styled from "styled-components";

export const Modal = styled.div`
  background: rgba(0, 0, 0, 0.32);
  z-index: 9;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
`

Modal.Container = styled.div`
  background: #FFFFFF;
  border: 1px solid #7e7e7e;
  border-radius: 4px;
  width: 90%;
  max-width: 90%;
  max-height: 80vh;
  overflow: auto;
  padding: 15px 10px;
  font-size: 14px;
`
Modal.Close = styled.button`
  background: none;
  outline: none;
  border: none;
  font-size: 12px;
  position: absolute;
  top: 5px;
  right: 5px;
  width: 14px;
  height: 14px;
`