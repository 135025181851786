import { GET, POST, PUT } from '../lib/axios';
import { fgetUser } from '../lib/Firebase';

const getRestaurantDetails = (productId) => {
  return new Promise((resolve, reject) => {
    GET(`ui/v1/menu/${productId}/orderTypes`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

function getOutletDetails(productId, data) {
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${productId}/getOutlet`, data)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

const getMenuDetails = (productId) => {
  return new Promise((resolve, reject) => {
    GET(`ui/v1/menu/${productId}`)
      .then((response) => resolve(response.data))
      .catch((error) => {
        reject(error);
      });
  });
};

const getProducts = (menuId) => {
  return new Promise((resolve, reject) => {
    GET(`ui/v1/menu/${menuId}`)
      .then((response) => resolve(response.data))
      .catch((error) => {
        reject(error);
      });
  });
};

async function UpdatePartialAcceptance(menu_id, order_id, data) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(
      `/restaurants/v1/${menu_id}/orders/${order_id}/updateEditedOrders`,
      data,
      header
    )
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function updateInStockOutStock(menuId, data) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${menuId}/inventory`, data, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function editStockData(menuId, type, data) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${type}/${menuId}/update`, data, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

//catalogueFeature/v1/:info/:establishment_id/update

// https://asia-south1-dev-catalogue.cloudfunctions.net/ui/v1/:menu_id/updateOrderType
//catalogueFeature/v1/GS_TEST/inventory

const getAddressList = () => {
  return new Promise((resolve, reject) => {
    GET(`ui/v1/getAllAddress`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

function addNewAddress(address) {
  return new Promise((resolve, reject) => {
    POST(`ui/v1/addAddress`, address)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

function newOrderRequest(productId, order) {
  return new Promise((resolve, reject) => {
    POST(`ui/v1/order/${productId}`, order)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

function orderByCash(orderId, order) {
  return new Promise((resolve, reject) => {
    PUT(`ui/v1/order${orderId}/paymentStatus`, order)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

function payOnline(productId, orderId) {
  return new Promise((resolve, reject) => {
    const data = {
      callback_url: window.location.origin + productId,
    };
    POST(`ui/v1/order/${orderId}/paymentLink`, data)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

function updateOnlinePaymentStatus(orderId, data) {
  return new Promise((resolve, reject) => {
    POST(`ui/v1/order/${orderId}/paymentStatus`, data)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}
function reserveTable(productId, data) {
  return new Promise((resolve, reject) => {
    POST(`ui/v1/tableRes/${productId}`, data)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}
function getQuoteData(locationData) {
  return new Promise((resolve, reject) => {
    POST(`ui/v1/checkDeliveryStatus`, locationData)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function getUserAddress(data) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`ui/v1/getUserAddress`, data, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}
async function getDeliveryPartner(data) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`ui/v1/getDeliveryPartner`, data, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function updateOrderType(menuId, data) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${menuId}/updateOrderType`, data, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function updateReminderStatus(menuId, data) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${menuId}/updateReminderStatus`, data, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

function cancelOrderByUser(orderId) {
  return new Promise((resolve, reject) => {
    POST(`ui/v1/cancelOrder/${orderId}`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function acceptOrderByLoc(menuId, orderId) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(
      `restaurants/v1/${menuId}/orders/${orderId}/acceptOrderByLoc`,
      '',
      header
    )
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function cancelOrderByLoc(menuId, orderId) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(
      `restaurants/v1/${menuId}/orders/${orderId}/cancelOrderByLoc`,
      '',
      header
    )
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function preparationStarted(menuId, orderId) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(
      `restaurants/v1/${menuId}/orders/${orderId}/preparationStarted`,
      '',
      header
    )
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function orderDispatched(orderId) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(
      `deliveryPartners/v1/self_delivery/${orderId}/markPickedUp`,
      '',
      header
    )
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function orderDelivered(orderId) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(
      `deliveryPartners/v1/self_delivery/${orderId}/markDelivered`,
      '',
      header
    )
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function markReady(menuId, orderId) {
  var header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`restaurants/v1/${menuId}/orders/${orderId}/markReady`, '', header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

function generateOTP({ phoneNumber, recaptchaToken }) {
  return new Promise((resolve, reject) => {
    POST(`ui/v1/otp/send/${phoneNumber}`, { recaptchaToken })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}
const getCategories = () => {
  return new Promise((resolve, reject) => {
    GET(`ui/v1/GS_FEDEMO/getCategoryURLS`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

function validateOTP({ phoneNumber, OTP }) {
  return new Promise((resolve, reject) => {
    POST(`ui/v1/otp/verify/${phoneNumber}`, { otp: OTP })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

const generateGipLink = (botname) => {
  return new Promise((resolve, reject) => {
    GET(`ui/v1/gipLink/${botname}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};
const getGipUser = () => {
  return new Promise((resolve, reject) => {
    GET(`ui/v1/getGipUser`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};
const markNotificationAsRead = (menu_id) => {
  return new Promise((resolve, reject) => {
    GET(`ui/v1/${menu_id}/markNotificationAsRead`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

async function getOfferList(menuId) {
  var header = await getAccessToken();
  return new Promise((resolve, reject) => {
    GET(`ui/v1/${menuId}/listOffers`, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}
async function updateOfferStatus(menu_id, offer_id, data) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${menu_id}/updateOfferStatus/${offer_id}`, data, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}
async function createOffer(menu_id, data) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${menu_id}/createOffer`, data, header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}
async function listActiveOffers(menu_id) {
  return new Promise((resolve, reject) => {
    GET(`ui/v1/${menu_id}/listActiveOffers`)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

function checkOfferAvailableStatus(menu_id, offer_id, data) {
  return new Promise((resolve, reject) => {
    POST(`ui/v1/${menu_id}/applyOffer/${offer_id}`, data)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}
async function updateNotifiedOrderStatus(menu_id, offer_id) {
  let header = await getAccessToken();
  return new Promise((resolve, reject) => {
    PUT(`ui/v1/${menu_id}/updateNotifiedOrderStatus/${offer_id}`, '', header)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
}

async function getReminderStatus(menu_id) {
  let header = await getAccessToken();
  if (header) {
    return new Promise((resolve, reject) => {
      GET(`ui/v1/${menu_id}/reminderStatus`, header)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  }
}

async function getAccessToken() {
  let token = await fgetUser();
  let header = null;
  if (token) {
    header = { Authorization: 'Bearer ' + token };
  }
  return header;
}
export {
  addNewAddress,
  getMenuDetails,
  getProducts,
  updateInStockOutStock,
  editStockData,
  getAddressList,
  getOutletDetails,
  newOrderRequest,
  orderByCash,
  payOnline,
  updateOnlinePaymentStatus,
  getRestaurantDetails,
  reserveTable,
  generateOTP,
  validateOTP,
  getQuoteData,
  getUserAddress,
  getDeliveryPartner,
  updateOrderType,
  acceptOrderByLoc,
  cancelOrderByLoc,
  preparationStarted,
  markReady,
  orderDispatched,
  orderDelivered,
  cancelOrderByUser,
  generateGipLink,
  getGipUser,
  markNotificationAsRead,
  getOfferList,
  updateOfferStatus,
  createOffer,
  listActiveOffers,
  checkOfferAvailableStatus,
  updateNotifiedOrderStatus,
  updateReminderStatus,
  getReminderStatus,
  getCategories,
  UpdatePartialAcceptance,
};
