export const Login = "/";
export const ONBOARD = "/onboard";
export const DASHBOARD = "/dashboard";
export const PASTORDER = "/pastOrders";
export const INVENTORY = "/inventory";
export const SETTINGS = "/settings";
export const OFFERS = "/offers";

export const RESISTER = "/:productId/dashboard/register";
export const LANDING = "/:productId";
export const HOMESCREEN = "/:productId/home";
export const PRODUCT = "/:productId/products";
export const TABLERESERVATION = "/:productId/tableReservation";
export const VEIWADDRESS = "/:productId/viewAddress";

export const ERROR404 = "/error/404";
export const ERROR500 = "/error/500";
