import React, {useEffect, useState} from "react";
import { Col, Container, Form, Row} from "react-bootstrap";
import './GoogleMap.scss';
import { Notification } from "../../helpers/notification";
import {GMAPContainer} from "./styles/googleMap.style";



export const GoogleMapComponentV2 = function ({address, setAddress, isSetting, isModal}) {
    const { lat=18.8035784, long=73.0316727 } = address;
    const [latLong, setLatLong] = useState({ lat, long });
    const [isFetchingLocation, setIsFetchingLocation] = useState(false);
    const [isMapLoaded, setIsMpaLoaded] = useState(false);
    const loadMarker = ({map, setAddress}) => {
        const marker = new window.google.maps.Marker({
            position: map.getCenter(),
            map,
        });
    
        map.addListener("center_changed", () => marker.setPosition(map.getCenter()));
    
        window.google.maps.event.addListener(map, 'dragend',
            () => latLongToAddress({lat: map.center.lat(), long: map.center.lng(), setAddress })
        );
    }
    
    const loadAutocomplete = ({map, ...props}) => {
        // Avoid paying for data that you don't need by restricting the set of place fields that are returned to just the address components and formatted address
        const options = {
            //types: [`(regions)`],
            componentRestrictions: { country: "in" },
            fields: ['formatted_address', 'geometry', 'address_components']
        };
    
        // Initialize Google Autocomplete
        const autocomplete = new window.google.maps.places.Autocomplete(
            document.getElementById(`autocomplete-v2`),
            options
        );
    
        // Fire Event when a suggested name is selected
        autocomplete.addListener('place_changed', () => handlePlaceSelect({autocomplete, map, ...props}));
    }
    
    function fetchLocation({setLatLong, setAddress, setIsFetchingLocation}){
        if(!navigator.geolocation) {
            Notification({
                type: 'error',
                displayMessage: 'This feature doesn\'t support for this device'
            })
        } else {
            setIsFetchingLocation(true);
            navigator.geolocation.getCurrentPosition((position) => {
                setLatLong({lat: position.coords.latitude, long: position.coords.longitude});
                latLongToAddress({lat: position.coords.latitude, long: position.coords.longitude, setAddress});
                window.map.setCenter({lat: position.coords.latitude, lng: position.coords.longitude});
                setIsFetchingLocation(false);
            }, (error) => {
                setIsFetchingLocation(false);
                Notification({
                    type: 'error',
                    displayMessage: 'Please allow us to get your location'
                })
            });
        }
    }
    
    function latLongToAddress({lat, long, setAddress}) {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: {lat, lng: long} }, (results, status) => {
                if (status === "OK") {
                    if (results[0]) {
                        const data = parseGeoAddress(results[0]);
                        
                        if(!isSetting){
                        setAddress(data)
                        document.getElementById('autocomplete-v2').value = results[0].formatted_address;
                        }
                        else {
                            // setAddress(data)
                            setAddress({...data, name:address.name ? address.name : "", house_number: address.house_number ? address.house_number : ""})
                        }
                    } else {
                        Notification({
                            type: 'error',
                            displayMessage: 'No results found'
                        });
                    }
                } else {
                    Notification({
                        type: 'error',
                        displayMessage: 'Geocoder failed due to: ' + status
                    });
                }
            }
        );
    }
    const parseGeoAddress = (place) => {
        const result = {
            long: place.geometry.location.lng(),
            lat: place.geometry.location.lat(),
            address: place.formatted_address,
            city: '',
            state: '',
            stateData: '',
            country: '',
            pincode: '',
            metadata: ''
        };
    
        place.address_components.forEach(address => {
            if(address.types.includes("administrative_area_level_2")) {
                result.city = address.long_name;
            } else if(address.types.includes("administrative_area_level_1")) {
                result.state = address.long_name;
                result.stateData = address.long_name;
            } else if(address.types.includes("country")) {
                result.country = address.long_name;
            } else if(address.types.includes("postal_code")) {
                result.pincode = address.long_name;
            } else if(address.types.includes("route")) {
                result.street_address = address.long_name;
            }
        })
    
        return result;
    }
    const handlePlaceSelect = ({autocomplete, setAddress, map, ...props}) => {
        const place = autocomplete.getPlace();
    
        if (!place.geometry || !place.geometry.location) {
            // User entered the name of a Place that was not suggested and pressed the Enter key, or the Place Details request failed.
            Notification({
                type: 'error',
                displayMessage: "No details available for input: '" + place.name + "'"
            });
            return;
        }
    
        // If the place has a geometry, then present it on a map.
        if (place.geometry.viewport) {
            map.fitBounds(place.geometry.viewport);
        } else {
            map.setCenter(place.geometry.location);
            map.setZoom(17);
        }
    
        // Extract City From Address Object
        const address = place.address_components;
        // Check if address is valid
        if (address) {
            const result = parseGeoAddress(place);
            if(!result.pincode || !result.city || !result.country) {
                console.log('Data missing, tring Geo location');
                latLongToAddress({lat: result.lat, long: result.long, setAddress});
                return;
            }
            if(!isSetting) {
            setAddress(result);
            }
            else {
                setAddress(result);
                setAddress({...result, name:address.name ? address.name : "", house_number: address.house_number ? address.house_number : ""})

            }
        }
    }


    useEffect(() => {
        if(!isMapLoaded) {
            window.map = new window.google.maps.Map(
                document.getElementById('googleMap-v2'), {
                    center: latLong,
                    zoom: 13,
                }
            );
            loadMarker({map: window.map, setAddress});
            loadAutocomplete({map: window.map, setLatLong, setAddress});
            if(!isSetting) {
            document.getElementById('autocomplete-v2').value= address.addressReference?'':address.address || '';
            }
            setIsMpaLoaded(true);
        } else {
            window.map.setCenter({lat, lng: long});
            window.map.setZoom(17);
        }
    }, [isMapLoaded, address.address_id, address.address, lat, latLong, long, setAddress]);

    return (<>
        {isSetting ? <div className="text-center p-2 mapBackground">Choose Your Location</div> : null}
        <GMAPContainer>
            <div style={{marginTop: isSetting && "0px"}} id='googleMap-v2' />
            
            <Container>
                <button disabled={isFetchingLocation} id="btn_location" onClick={() => fetchLocation({setIsFetchingLocation, setLatLong, setAddress})}>
                    { !isFetchingLocation ? <><i className='fa fa-crosshairs' /> Use my current location</> : 'Fetching' }
                </button>
                {!isSetting ? <><div className="separator">
                    <div className='separatorText'>OR</div>
                </div> <Row>
                    <Col>
                        <Form.Group>
                            <Form.Control className="input-font-size" id="autocomplete-v2" placeholder="Enter Location Manually"/>
                        </Form.Group>
                    </Col>
                </Row> </>: <Row>
                    <Col className="text-center p-2">
                        <button className="btn btn-primary" onClick={() => {console.log("success"); isModal(false); console.log("address---->", address)}}>Use This Location</button>
                    </Col>
                </Row> }
                
            </Container>
        </GMAPContainer>
        </>
    )
}