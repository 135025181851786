import React from "react";
import { Spinner } from "./styles/spinner";
import { Spinner as Loader } from "react-bootstrap";
import { useState, useEffect, useContext } from "react";
import { RestaurantsInfo } from "../../contexts/restaurantInfo";

export default function SpinnerComponent({ ...props }) {
  const { restaurant, setRestaurantColor } = useContext(RestaurantsInfo);
  return (
    <Spinner {...props}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Loader
          style={{ color: `${restaurant?.meta_data?.uiColor}`}}
          animation="border"
        />
      </div>
      {props.message ? (
        <div style={{ color: `${restaurant?.meta_data?.uiColor}` }}>
          <b>{props.message}</b>
        </div>
      ) : (
        ""
      )}
    </Spinner>
  );
}
