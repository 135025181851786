import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Login } from '../constants/routes';

export function IsUserRedirect({ user, isNewUser, newUserPath, loggedInPath, children, ...rest }) {
    return (
        <Route
            {...rest}
            render={() => {
                

                if (!user) {
                    return children;
                }

                if (user) {
                    if(isNewUser) {
                        return (
                            <Redirect
                                to={{
                                    pathname: newUserPath,
                                }}
                            />
                        );
    
                    }
                    else{
                    return (
                        <Redirect
                            to={{
                                pathname: loggedInPath,
                            }}
                        />
                    )}
                }

                return null;
            }}
        />
    );
}

export function ProtectedRoute({ user, children, ...rest }) {
    return (
        <Route
            {...rest}
            render={({ location }) => {
                if (user) {
                    return children;
                }

                if (!user) {
                    return (
                        <Redirect
                            to={{
                                pathname: Login,
                                state: { from: location },
                            }}
                        />
                    );
                }

                return null;
            }}
        />
    );
}

export function CookieProtectedRoute({ cookie, children, ...rest }) {
    return (
        <Route
            {...rest}
            render={({ location }) => {
                if (cookie) {
                    return children;
                } else {
                    return window.location = `https://gipw.gupshup.io/is/?redirectUrl=${window.location.href}`;
                }
            }}
        />
    );
}