import { createContext, useContext, useEffect, useState } from "react";
import { getRestaurantDetails } from "../utils/API";
import { ERROR404, ERROR500 } from "../constants/routes";

export const RestaurantsInfo = createContext(null);

export const RestaurantsInfoContext = function ({ children, ...props }) {
  const [productId, setProductId] = useState(null);
  const [restaurant, setRestaurant] = useState(null);
  useEffect(() => {
    if (productId) {
      getRestaurantDetails(productId)
        .then((res) => {
          setRestaurant(res);
        })
        .catch((error) => {
          if (error.response) {
            switch (error.response.status) {
              case 500: {
                window.location.href = ERROR500;
                break;
              }
              case 404: {
                window.location.href = ERROR404;
                break;
              }
              default:
                window.location.href = ERROR500;
            }
          }
        });
    }
  }, [productId]);
  return (
    <RestaurantsInfo.Provider value={{ restaurant, setProductId }}>
      {children}
    </RestaurantsInfo.Provider>
  );
};
export function useRestaurantsInfoContext() {
  const { restaurant, productId, setProductId, setRestaurant } =
    useContext(RestaurantsInfo);
  return { productId, restaurant, setProductId, setRestaurant };
}
