import React from "react";
import './Confirmation.scss';

import loading from '../../assets/icons/loading.svg';
import {Button, Container, Row, Col} from "react-bootstrap";
import {Link, useParams} from "react-router-dom";

export default function Confirmation({
        confirmationMsg='Your reservation will be confirmed in few minutes',
        logo='https://image3.mouthshut.com/images/imagesp/925805996s.png'
    }) {
    const { productId } = useParams();
    return (
        <React.Fragment>
            <Container>
                <Row>
                    <Col xs={12}>
                        <img src={logo} alt="logo" className="confirmLogo" />
                    </Col>
                    <Col xs={12}>
                        <h5 className="text-center text-uppercase">Thank you</h5>
                        <img src={loading} alt="loader" className="loader" />
                        <h5 className="text-center">{confirmationMsg}</h5>
                    </Col>
                    <Col xs={12} className="mainMenuBtn">
                        <Link to={`/${productId}/home`}>
                            <Button variant="danger" size="lg" block style={{backgroundColor: '#EE2F37', textDecoration:'none'}}>
                                Go to main menu
                            </Button>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}