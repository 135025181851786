import React, {lazy} from "react";
import './SeatsCounter.scss'
import {Card, Col, Container, Row} from "react-bootstrap";

const DateTimePicker = lazy(() => import('react-datetime-picker'));

export default function SeatsCounter({noOfSeats, setNoOfSeats, time, onTimeChange}) {
    function updateSeats(count) {
        if(noOfSeats > 0 || count > 0) {
            setNoOfSeats(noOfSeats+count);
        }
    }

    return (
        <Card className="counterBody">
            <Container>
                <Row>
                    <Col xs={12}>
                        <h4 className="text-center">Number of person</h4>
                    </Col>
                    <Col xs={12}>
                        <ul className="counter list-inline">
                            <li className="list-inline-item">
                                <button className="btn btn-outline-danger counterBtn" onClick={() => updateSeats(-1)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                         fill="none" stroke="#EE2F37" strokeWidth="2" strokeLinecap="round"
                                         strokeLinejoin="round" className="feather feather-minus-circle">
                                        <circle cx="12" cy="12" r="10"></circle>
                                        <line x1="8" y1="12" x2="16" y2="12"></line>
                                    </svg>
                                </button>
                            </li>
                            <li className="list-inline-item">
                                <div className="counterInput">{noOfSeats}</div>
                            </li>
                            <li className="list-inline-item">
                                <button className="btn btn-outline-danger counterBtn" onClick={() => updateSeats(1)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                         fill="none" stroke="#EE2F37" strokeWidth="2" strokeLinecap="round"
                                         strokeLinejoin="round" className="feather feather-plus-circle">
                                        <circle cx="12" cy="12" r="10"></circle>
                                        <line x1="12" y1="8" x2="12" y2="16"></line>
                                        <line x1="8" y1="12" x2="16" y2="12"></line>
                                    </svg>
                                </button>
                            </li>
                        </ul>
                    </Col>
                    <Col xs={12}>
                        <div className="datePicker">
                            <DateTimePicker
                                format="dd-MM-y h:mm a"
                                minDate={new Date()}
                                onChange={onTimeChange}
                                value={time}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </Card>
    )
}

