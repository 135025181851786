import React from "react";
import { useHistory, useParams } from "react-router-dom";
import './NavigationHeader.scss';
import leftArrow from '../../assets/icons/arrow-left.svg';

export default function NavigationHeader({title, logo, backClickEvent, ...props}) {
    const logoImg = logo ? <img className="logo"  alt="Restaurant Logo" src={logo}/> : null;
    const { productId } = useParams();
    const history = useHistory();

    const onBackClickEvent = () => {
        if (backClickEvent) {
            backClickEvent();
            return;
        }
        history.push(`/${productId}`)
    }

    return (
        <header className="headerBlock" {...props}>
            <a onClick={onBackClickEvent}>
                <img className="leftArrow"  alt="Back button" src={leftArrow}/>
            </a>
            { logoImg }
            { title }
        </header>
    )
}