import React, { createContext, useContext, useEffect, useState } from "react";
import { OrderType } from "../constants/variables";
import { useHistory, useParams } from "react-router-dom";
import { LANDING, TABLERESERVATION, VEIWADDRESS } from "../constants/routes";
import { useQuery, useProductId } from "../hooks";
import usePersistedState from "../helpers/persistent";
import { useRestaurantsInfoContext } from "./restaurantInfo";

export const OrderTypeContext = createContext(null);

export const OrderTypeContextProvider = function ({ children, ...props }) {
  const [subCategoryName, setSubCategoryName] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [subCategoryId, setSubCategoryId] = useState(null);
  const productId = useProductId();
  const [orderType, setOrderType] = usePersistedState(
    null,
    productId,
    "orderType"
  );
  const [metaData, setMetaData] = usePersistedState({}, productId, "metaData");
  let table = useQuery("table");
  table = table ? table.split("?")[0] : null;
  let room = useQuery("room");
  room = room ? room.split("?")[0] : null;

  useEffect(() => {
    if (orderType === OrderType.DINEIN) {
      if (table) {
        setMetaData({ table_number: table, edit_disable: true });
      } else if (metaData.table_number) {
        setMetaData({ table_number: metaData.table_number });
      }
    } else if (orderType === OrderType.INROOMDINEIN) {
      if (room) {
        setMetaData({ room_number: room, edit_disable: true });
      } else if (metaData.room_number) {
        setMetaData({ room_number: metaData.room_number });
      }
    }
  }, [orderType]);

  return (
    <OrderTypeContext.Provider
      value={{
        orderType,
        metaData,
        setOrderType,
        setMetaData,
        categoryId,
        setSubCategoryId,
        subCategoryId,
        setCategoryId,
        subCategoryName,
        setSubCategoryName,
      }}
    >
      {children}
    </OrderTypeContext.Provider>
  );
};

export const useOrderTypeContext = function () {
  const { orderType, setOrderType, ...rest } = useContext(OrderTypeContext);
  const { productId } = useParams();
  const history = useHistory();
  const orderTypeQueryParams = useQuery("orderType");

  const { setProductId } = useRestaurantsInfoContext();
  if (!orderType) {
    switch (orderTypeQueryParams) {
      case OrderType.DELIVERY: {
        setProductId(productId);
        setOrderType(OrderType.DELIVERY);
        if (rest.metaData && rest.metaData.address) {
          setOrderType(OrderType.DELIVERY);
        } else {
          history.push(VEIWADDRESS.replace(":productId", productId));
        }
        break;
      }
      case OrderType.TABLERES: {
        setProductId(productId);
        setOrderType(OrderType.TABLERES);
        history.push(TABLERESERVATION.replace(":productId", productId));
        break;
      }
      case OrderType.DINEIN: {
        setProductId(productId);
        setOrderType(OrderType.DINEIN);
        break;
      }
      case OrderType.TAKEAWAY: {
        setProductId(productId);
        setOrderType(OrderType.TAKEAWAY);
        break;
      }
      case OrderType.INROOMDINEIN: {
        setProductId(productId);
        setOrderType(OrderType.INROOMDINEIN);
        break;
      }
      default: {
        setProductId(productId);
        history.push(LANDING.replace(":productId", productId));
      }
    }
  }
  return { orderType, ...rest };
};
