import { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useDashboardState } from "../helpers/persistent";
import { fstore } from "../lib/Firebase";

export const StoreInfo = createContext(null);

export const StoreInfoContext = ({ user, children }) => {
  const { productId } = useParams();
  const [storeData, setStoreData] = useState(null);
  const [ordersCount, setOrdersCount] = useState({
    pendingOrderCount: -1,
    cancelledOrderCount: -1,
  });
  const [reminderNotification, setReminderNotification] = useDashboardState(
    false,
    "reminder"
  );
  const [currentStore, setCurrentStore] = useDashboardState(
    null,
    "currentStore"
  );
  const [currentStoreOrders, setCurrentStoreOrders] = useDashboardState(
    [],
    "orders"
  );
  const [isStoreDataLoading, setIsStoreDataLoading] = useState(false);
  const [orderUnsubscriber, setOrderUnsubscriber] = useState();
  useEffect(() => {
    if (user) {
      setIsStoreDataLoading(true);
      getUserStoreData(user)
        .then((result) => {
          setStoreData(result);
          setIsStoreDataLoading(false);
          let isStoreAvailable = false;
          for (let i = 0; currentStore && i < result.length; i++) {
            if (result[i].storeId === currentStore.storeId) {
              isStoreAvailable = true;
            }
          }

          if ((!isStoreAvailable || !currentStore) && result[0]) {
            setCurrentStore(result[0]);
          }
        })
        .catch((error) => {
          setIsStoreDataLoading(false);
          console.log(error);
        });
    }
  }, [currentStore]);

  return (
    <StoreInfo.Provider
      value={{
        isStoreDataLoading,
        storeData,
        setStoreData,
        currentStore,
        setCurrentStore,
        orderUnsubscriber,
        setOrderUnsubscriber,
        currentStoreOrders,
        setCurrentStoreOrders,
        ordersCount,
        setOrdersCount,
        reminderNotification,
        setReminderNotification,
      }}
    >
      {children}
    </StoreInfo.Provider>
  );
};

function getUserStoreData(user) {
  return new Promise((resolve, reject) => {
    fstore
      .collection("dashboardUsers")
      .doc(getUserProvider(user))
      .collection("restaurantId")
      .get()
      .then((snapshot) => {
        const result = snapshot.docs.map((doc) => {
          const data = doc.data();
          data.storeId = doc.id;
          return data;
        });
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function getUserProvider(user) {
  switch (user.providerData[0].providerId) {
    case "phone":
      return user.phoneNumber;
      break;
    case "google.com":
      return user.email;
      break;
    case "facebook":
      return user.phoneNumber;
      break;
    default:
      return user.email;
  }
}

export const useStoreInfoContext = () => {
  const {
    isStoreDataLoading,
    storeData,
    setStoreData,
    currentStore,
    setCurrentStore,
    orderUnsubscriber,
    setOrderUnsubscriber,
    currentStoreOrders,
    setCurrentStoreOrders,
    ordersCount,
    setOrdersCount,
    reminderNotification,
    setReminderNotification,
  } = useContext(StoreInfo);

  return {
    isStoreDataLoading,
    storeData,
    setStoreData,
    currentStore,
    setCurrentStore,
    orderUnsubscriber,
    setOrderUnsubscriber,
    currentStoreOrders,
    setCurrentStoreOrders,
    ordersCount,
    setOrdersCount,
    reminderNotification,
    setReminderNotification,
  };
};
