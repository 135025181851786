import styled from 'styled-components'

export const Spinner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-content: center;
  background: rgba(255, 255, 255, ${props => props.opacity || '1'});
  z-index: 9;
`;