import IntlTelInput from "react-intl-tel-input/dist/components/IntlTelInput";
import styled from "styled-components";

export const PhoneInput = styled.div``;

PhoneInput.numberInput = styled.input`
:focus & {
    border-color = red !important;
}

`;
