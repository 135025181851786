import sound from "../assets/audio/sound.mp3";
import {toast} from "react-toastify";

const toaster = (type, displayMessage, {...toasterOptions}) => {
    switch (type) {
        case 'success': {
            toast.success(displayMessage, {hideProgressBar: true, ...toasterOptions});
            break;
        }
        case 'error': {
            toast.error(displayMessage, {hideProgressBar: true, ...toasterOptions});
            break;
        }
        case 'warn': {
            toast.warn(displayMessage, {hideProgressBar: true, ...toasterOptions});
            break;
        }
        case 'info': {
            toast.info(displayMessage, {hideProgressBar: true, ...toasterOptions});
            break;
        }
        default: {
            toast(displayMessage, {hideProgressBar: true, ...toasterOptions});
            break;
        }
    }
}
export function Notification({type, displayMessage="You have new notification", toasterOptions, isAudio}) {
    toaster(type, displayMessage, toasterOptions);

    if(isAudio) {
        const audio = new Audio(sound);
        audio.play();
    }
}