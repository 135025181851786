import React, { useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import * as ROUTES from "./constants/routes";
import { IsUserRedirect, ProtectedRoute } from "./helpers/routes";
import { useAuthListener } from "./hooks";
import { Spinner } from "./Components";
import "./app.scss";
import "./assets/scss/dashboardStyle.scss";
import { RestaurantsInfoContext } from "./contexts/restaurantInfo";
import { OrderTypeContextProvider } from "./contexts/orderTypeContext";
import { ToastContainer } from "react-toastify";
import { StoreInfoContext } from "./contexts/store";
import jsCookie from "js-cookie";

const LandingPage = React.lazy(() => import("./pages/LandingPage/LandingPage"));
const HomeScreen = React.lazy(() => import("./pages/HomeScreen/HomeScreen"));
const Product = React.lazy(() => import("./pages/ProductV2/ProductV2"));
const TableReservation = React.lazy(() =>
  import("./pages/TableReservation/TableReservation")
);
const Login = React.lazy(() => import("./pages/login/Login"));
const Onboard = React.lazy(() => import("./pages/Onboard/Onboard"));
const Register = React.lazy(() => import("./pages/register/Register"));
const Page404 = React.lazy(() => import("./pages/page404/Page404"));
const Page500 = React.lazy(() => import("./pages/page500/Page500"));

const AddressBooks = React.lazy(() =>
  import("./pages/AddressBook/AddressBook")
);
const OrderConfirmation = React.lazy(() =>
  import("./pages/OrderConfirmation/OrderConfirmation")
);
const TheLayout = React.lazy(() => import("./containers/TheLayout/TheLayout"));

export default function App() {
  const [isNewUser, setIsNewUser] = useState();
  const { user } = useAuthListener();
  const [ownerId, setOwnerId] = useState();
  // Set default Cookie || For development purpose only
  if (process.env.REACT_APP_GIP_USERID_COOKIE) {
    jsCookie.set("gipuserid", process.env.REACT_APP_GIP_USERID_COOKIE);
  }


  return (
    <BrowserRouter>
      <React.Suspense fallback={Spinner}>
        <RestaurantsInfoContext>
          <OrderTypeContextProvider>
            <Switch>
              <ProtectedRoute exact user={user} path={ROUTES.PASTORDER}>
                <StoreInfoContext user={user}>
                  <TheLayout />
                </StoreInfoContext>
              </ProtectedRoute>
              <ProtectedRoute exact user={user} path={ROUTES.OFFERS}>
                <StoreInfoContext user={user}>
                  <TheLayout />
                </StoreInfoContext>
              </ProtectedRoute>
              <ProtectedRoute exact user={user} path={ROUTES.INVENTORY}>
                <StoreInfoContext user={user}>
                  <TheLayout />
                </StoreInfoContext>
              </ProtectedRoute>
              <ProtectedRoute exact user={user} path={ROUTES.SETTINGS}>
                <StoreInfoContext user={user}>
                  <TheLayout />
                </StoreInfoContext>
              </ProtectedRoute>
              <ProtectedRoute exact user={user} path={ROUTES.DASHBOARD}>
                <StoreInfoContext user={user}>
                  <TheLayout />
                </StoreInfoContext>
              </ProtectedRoute>
              <ProtectedRoute exact user={user} path={ROUTES.RESISTER}>
                <Register />
              </ProtectedRoute>
              <ProtectedRoute exact user={user} path={ROUTES.ONBOARD}>
                <Onboard ownerId={ownerId} />
              </ProtectedRoute>

              <IsUserRedirect
                exact
                user={user}
                isNewUser={isNewUser}
                newUserPath={ROUTES.ONBOARD}
                loggedInPath={ROUTES.DASHBOARD}
                path={ROUTES.Login}
              >
                <Login setOwnerId={setOwnerId} setIsNewUser={setIsNewUser} />
              </IsUserRedirect>

              <Route
                exact
                path={ROUTES.TABLERESERVATION}
                render={() => <TableReservation />}
              />
              <Route
                exact
                path={ROUTES.HOMESCREEN}
                render={() => <HomeScreen />}
              />
              <Route exact path={ROUTES.PRODUCT} render={() => <Product />} />

              <Route
                exact
                path={ROUTES.VEIWADDRESS}
                render={() => <AddressBooks addNewAddress={false} />}
              />
              <Route
                exact
                path={ROUTES.LANDING}
                render={() => <LandingPage />}
              />
              {/* <Route exact path={ROUTES.ORDERCONFIRMATION} render={() => <OrderConfirmation />}/> */}
              <Route
                exact
                path={ROUTES.ERROR500}
                name="Page 500"
                render={() => <Page500 />}
              />
              <Route
                path={ROUTES.ERROR404}
                name="Page 404"
                render={() => <Page404 />}
              />
              <Route name="Page 404" render={() => <Page404 />} />
            </Switch>
          </OrderTypeContextProvider>
        </RestaurantsInfoContext>
        <ToastContainer />
      </React.Suspense>
    </BrowserRouter>
  );
}
