import "core-js";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { firebase } from "./lib/Firebase";
import { FirebaseContext } from "./contexts/firebase";

import { icons } from "./assets/icons";

import { Provider } from "react-redux";
import store from "./store";
import "normalize.css";
import { GlobalStyles } from "./global-styles";

React.icons = icons;
ReactDOM.render(
  <FirebaseContext.Provider value={{ firebase }}>
    <Provider store={store}>
      <GlobalStyles />
      <App />
    </Provider>
  </FirebaseContext.Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
